<template>
  <div v-if="product" class="wrap">
    <close-title-basket :key="headerKey" :title="product.den_name">
      <nav-book-mark-button
        :key="isPickBrand"
        :pickItemId="product.den_id"
        :value="isPickBrand"
        type="p"
      />
    </close-title-basket>

    <div class="layout_body search_contain">
      <template v-if="product.is_gubun === '일반연동'">
        <iframe
          :height="`${height}px`"
          :src="product.item_url"
          frameborder="0"
          seamless="seamless"
          style="width: 100%"
        />
      </template>
      <template v-else>
        <div class="shoppingMall" v-html="product.cit_content" />
      </template>
      <div class="order_content">
        <div class="disflex">
          <div class="left">
            <router-link :to="`/product/review/${product.cit_id}`">
              <span class="disflex">
                {{ $t('Mypage.Review') }}
                <b> ({{ product.cit_review_count }}) </b>
              </span>
              <review-score :value="product.cit_review_average" />
            </router-link>
          </div>

          <template v-if="!isBuyProduct">
            <template v-if="product.is_sale === 'y'">
              <h6 class="prod_price">
                <span class="discount disblock align_right">
                  {{ product.percent }}%
                </span>
                <span>
                  {{ product.cit_sale_price.numberFormat() }}
                </span>
              </h6>
            </template>
            <template v-else>
              <h6 class="prod_price">
                <span></span>
                <span>
                  {{ product.cit_price.numberFormat() }}
                </span>
              </h6>
            </template>
            <button class="black_btn buy_btn" @click="isBuyProduct = true">
              {{ $t('Review.Purchase') }}
            </button>
          </template>
          <template v-else>
            <button
              class="white_btn buy_btn btn_pop_open"
              style="display: block"
              @click="handleClickBasket"
            >
              {{ $t('Basket.BasketButton') }}
            </button>

            <button class="black_btn buy_btn" @click="handleClickBuy">
              {{ $t('Review.BuyNow') }}
            </button>
          </template>
        </div>
      </div>

      <product-option-list
        v-if="product"
        v-show="isBuyProduct"
        ref="productVue"
        v-model="selected"
        :delivery="delivery"
        :product="product"
        :reset="isBuyProduct"
        @close="isBuyProduct = false"
      />
    </div>
    <recently-popup v-if="isShowRecently" @close="handleCloseRecently" />
    <!--    상세 페이지 하단바 주석 처리 -->
    <div class="product_navi">
      <ul class="disflex">
        <li><a class="back_btn" @click="$router.go(-1)"></a></li>
        <li><a class="forward_btn" @click="$router.go(1)"></a></li>
        <li>
          <a
            class="btn_pop_open sns_btn"
            data-popup="sns_share_popup"
            @click="handleClickShare"
          ></a>
        </li>
        <li>
          <nav-book-mark-button
            :key="isPickItem"
            :pickItemId="id"
            :value="isPickItem"
            type="i"
          />
        </li>
        <li>
          <a class="recently_btn" @click="isShowRecently = true"></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CloseTitleBasket from '@Components/layouts/components/headers/CloseTitleBasket.vue';
import ProductAPI from '@/apis/ProductAPI';
import { reactive, ref, toRefs } from 'vue-demi';
import ProductOptionList from '../../components/ui/ProductOptionList/ProductOptionList.vue';
import ReviewScore from '../../components/ui/ReviewScore/ReviewScore.vue';
import useToast from '../../composables/dialogs/useToast';
import useConfirm from '../../composables/dialogs/useConfirm';
import usePick from '../../composables/user/usePick';
// import { getCookie, setCookie } from '../../utils/GlobalUtils';
import RecentlyPopup from './components/RecentlyPopup.vue';
import NavBookMarkButton from '../../components/ui/Buttons/NavBookMarkButton.vue';
import useShare from '../../composables/dialogs/useShare';

export default {
  components: {
    CloseTitleBasket,
    ProductOptionList,
    ReviewScore,
    RecentlyPopup,
    NavBookMarkButton,
  },

  setup(_, context) {
    const id = Number(context.root.$route.params.id);
    // const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const { isPick } = usePick();
    const { showShare } = useShare();
    const productVue = ref(null);
    const state = reactive({
      selected: [],
      product: null,
      provider: null,
      delivery: null,
      headerKey: Date.now(),
      isPickItem: false,
      isPickBrand: false,
      isBuyProduct: false,
      isShowRecently: false,
      selectedItem: [],
    });

    const createProductData = (data) => {
      const product = { ...data };

      const sale = product.cit_sale_price;
      const price = product.cit_price;
      const percent = 100 - Math.floor((sale / price) * 100);

      product.percent = percent;

      return product;
    };

    // 최근 본 항목
    const recentItems = JSON.parse(
      localStorage.getItem('recent-items') ?? '[]'
    );
    console.log(recentItems, 'recentItems!!!!!');
    // 해당 상품을 본 기록이 있다면 해당 기록을 지우고
    // 첫번째 자리에 아이디 값을 넣어 저장
    const idx = recentItems.indexOf(id);

    if (idx > -1) recentItems.splice(idx, 1);

    // 최근본 항목을 1000개까지만 저장
    const newRecent = [id, ...recentItems].slice(0, 1000);
    console.log(newRecent, 'newRecent!!!!!');
    // 최근 본 항목 아이디 저장
    console.log(JSON.stringify(newRecent), '------------------');
    // setCookie('recent-items', JSON.stringify(newRecent));
    localStorage.setItem('recent-items', JSON.stringify(newRecent));
    const loadProduct = async () => {
      const { data } = await ProductAPI.getProduct(id);
      console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$#################');
      console.log(data);
      console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$#################');
      state.product = createProductData(data.result_data);
      state.provider = data.provider;
      state.delivery = data.delivery;

      state.isPickItem = isPick('items', id);
      state.isPickBrand = isPick('brands', state.product.den_id);
    };

    loadProduct();

    return {
      id,
      productVue,
      height: window.innerHeight - 144,
      ...toRefs(state),
      handleClickBuy() {
        let isSoldout = false;

        state.selected.forEach((item) => {
          if (isSoldout === false && item.order_soldout === 'y') {
            isSoldout = true;
          }
        });

        if (isSoldout) {
          addMsg(context.root.$t('Review.SoldOut'));
          return;
        }

        if (state.selected.length === 0) {
          addMsg(context.root.$t('Pick.AlertSelect'));
          return;
        }

        const selected = state.selected.map((item) => ({
          cit_id: item.cit_id,
          cde_id: item.cde_id,
          qty: item.qty,
          // den_id: state.provider.den_id,
        }));
        //provider
        const providers = {
          [state.provider.den_id]: { provider: state.provider },
        };
        console.log('@@@#@#@#@#@#@#');
        console.log(providers);
        console.log('@@@#@#@#@#@#@#');

        state.selectedItem = selected;
        localStorage.setItem('selected', JSON.stringify(selected));
        localStorage.setItem('providers', JSON.stringify(providers));
        localStorage.setItem(
          'selectedItem',
          JSON.stringify(state.selectedItem)
        );

        context.root.$router.push({
          path: '/order',
          // query: {
          //   selected: JSON.stringify(selected),
          //   // coupons: JSON.stringify(state.selecteCoupons),
          //   // couponPrice: state.coupon_price,
          //   providers: JSON.stringify(providers),
          //   selectedItem: JSON.stringify(state.selectedItem),
          // },
        });
      },

      async handleClickBasket() {
        if (state.selected.length === 0) {
          addMsg(context.root.$t('Pick.AlertSelect'));
          return;
        }
        let items = localStorage.getItem('basket');
        items = items ? JSON.parse(items) : [];
        let isPush = true;

        if (isPush) {
          state.selected.map((selected) => {
            items.map((item) => {
              if (selected.cde_id == item.cde_id) {
                item.qty = item.qty + selected.qty;
                console.log(item);
              }
            });
            selected.den_id = state.provider.den_id;
            items.push(...items, selected);
          });

          items = items.reduce(function (acc, v) {
            if (acc.findIndex(({ cde_id }) => cde_id === v.cde_id) === -1) {
              acc.push(v);
            }
            return acc;
          }, []);
          localStorage.setItem('basket', JSON.stringify(items));
          productVue.value.handleReset();
          state.selected = [];
          state.isBuyProduct = false;

          showConfirm(context.root.$t('Pick.AlertMove'), () => {
            context.root.$router.push('/cart');
          });

          state.headerKey = Date.now();
        } else {
          addMsg(context.root.$t('Pick.Duplication'));
        }
      },

      handleClickShare() {
        const url =
          state.product.is_gubun === '일반연동'
            ? state.product.cit_file_1
            : `${process.env.VUE_APP_FILE_URL}${state.product.cit_file_1}`;

        showShare({
          url: `${location.origin}/product/${id}`,
          title: state.product.cit_name,
          image: url,
          desc: state.product.cit_summary,
        });
      },

      handleCloseRecently() {
        state.isShowRecently = false;
        state.isPickItem = isPick('items', id);
      },
    };
  },
};
</script>

<style></style>
