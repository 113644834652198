<template>
  <down-full-up-popup
    :title="$t('Mypage.RecentlyProduct')"
    class="recently-popup"
    @close="$emit('close')"
  >
    <recently-view slot="body" />
    <template slot="footer">
      <button type="button" style="width: 100%" @click="$emit('close')">
        {{ $t('Review.Close') }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import RecentlyView from '../../mypages/RecentlyView.vue';
export default {
  components: { DownFullUpPopup, RecentlyView },
};
</script>

<style>
.recently-popup .pop_body {
  padding-bottom: 0;
  min-height: auto;
}
.recently-popup .layout_body {
  padding: 0;
  min-height: auto;
}

.recently-popup .wrap .layout_footer {
  display: none;
}
</style>
