<template>
  <div class="wrap review_detail">
    <back-title :title="$t('Mypage.Review')" />

    <div class="layout_body box_p0 re_layout">
      <div v-if="product" class="review_con disflex">
        <product-image :item="product" class="review_pro" />
        <div class="detail_pro">
          <h6 class="fontEn font2">{{ product.cit_name }}</h6>
          <p class="font2">
            {{ product.cit_summary }}
          </p>
        </div>
      </div>

      <review-item v-if="review" :item="review" :isShowComment="false" />
      <div v-if="comments" class="review_box inner">
        <h6 class="comment_num">
          {{ $t('Review.Comment') }} {{ comments.length }}
        </h6>
        <!--본인 댓글-->
        <div v-for="comment in comments" :key="comment.crc_id">
          <div class="pro_desc_box type3 inner">
            <image-bg-src :src="comment.img_url" class="profile_img" />
            <div class="profile_user disflex">
              <h3 class="name">{{ comment.mem_userid }}</h3>
              <template
                v-if="comment.mem_id === info.mem_id || info.mem_type === 'm'"
              >
                <button
                  type="button"
                  class="close btn_pop_open"
                  @click="handleClickDelete(comment.crc_id)"
                />
              </template>
              <!--본인댓글만 x버튼 노출-->
            </div>
          </div>

          <div class="comment_box">
            <p class="comment_detail">
              {{ comment.comment }}
            </p>
            <p class="desc date">{{ comment.ins_dtm.substr(0, 10) }}</p>
          </div>
        </div>
      </div>
      <infinite-loading
        v-if="hasMore"
        :identifier="infiniteId"
        @infinite="loadComments"
      />

      <div class="comment_write">
        <input
          v-model="comment"
          type="text"
          :placeholder="$t('Review.CommentWrite')"
          maxlength="500"
        />
        <button type="submit" class="black_btn" @click="handleClickSubmit">
          {{ $t('Review.Complete') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BackTitle from '../../components/layouts/components/headers/BackTitle.vue';
import ProductAPI from '@/apis/ProductAPI';
import { reactive, toRefs } from 'vue-demi';
import ProductImage from '../../components/ui/ProductImage/ProductImage.vue';
import ReviewItem from './components/ReviewItem.vue';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import useConfirm from '../../composables/dialogs/useConfirm';
import useToast from '../../composables/dialogs/useToast';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    BackTitle,
    ProductImage,
    ReviewItem,
    ImageBgSrc,
    InfiniteLoading,
  },
  setup(_, context) {
    const { info } = useUserInfo();
    const { showConfirm } = useConfirm();
    const { addMsg } = useToast();
    const { r_id, id } = context.root.$route.params;

    const state = reactive({
      product: null,
      review: null,
      comments: [],
      comment: '',
      hasMore: true,
      infiniteId: Date.now(),
    });

    const commentParams = {
      id: r_id,
      page: 0,
      limit: 10,
    };

    const loadProduct = async () => {
      const { data } = await ProductAPI.getProduct(id);

      state.product = data.result_data;
    };

    const loadReview = async () => {
      const { data } = await ProductAPI.getReviewDetail(r_id);

      state.review = data.result_data;
    };

    const loadComments = async (attr) => {
      const { data } = await ProductAPI.getReviewComment(commentParams);

      state.comments = [...state.comments, ...data.result_data];

      if (data.result_data.length !== commentParams.limit) {
        attr?.complete();
        state.hasMore = false;
      } else {
        commentParams.page++;
        attr?.loaded();
      }
    };

    const deleteComment = async (id) => {
      await ProductAPI.deleteComment({
        id,
      });

      addMsg(context.root.$t('Review.CommentDel'));

      state.comments = state.comments.filter(
        (comment) => comment.crc_id !== id
      );
    };

    const createComment = async () => {
      const { data } = await ProductAPI.createComment({
        cre_id: r_id,
        mem_id: info?.mem_id,
        comment: state.comment,
      });

      addMsg(context.root.$t('Review.CommentComplete'));

      state.comments = [data.result_data, ...state.comments];

      state.comment = '';
    };

    loadProduct();
    loadReview();

    return {
      info: info ?? {},
      loadComments,
      ...toRefs(state),

      handleClickDelete(id) {
        showConfirm(context.root.$t('Review.CommentConfirm'), () => {
          deleteComment(id);
        });
      },

      handleClickSubmit() {
        createComment();
      },
    };
  },
};
</script>

<style>
.review_detail .date {
  font-size: 12px;
}
</style>
