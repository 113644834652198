<template>
  <a :class="`mark_btn ${checked ? 'on' : ''}`" @click="handleClick" />
</template>

<script>
import { ref } from 'vue-demi';
import useToast from '@/composables/dialogs/useToast';
import useUserInfo from '../../../composables/user/useUserInfo';
import useConfirm from '../../../composables/dialogs/useConfirm';
import CommonAPI from '@/apis/CommonAPI';
import usePick from '../../../composables/user/usePick';

export default {
  props: ['value', 'type', 'pickItemId'],
  setup(props, context) {
    const { update } = usePick();
    const checked = ref(props.value);
    const { addMsg } = useToast();
    const { info } = useUserInfo();
    const { showConfirm } = useConfirm();

    const updatePick = async () => {
      checked.value = !checked.value;

      await CommonAPI.updatePick({
        type: props.type,
        mem_id: info?.mem_id,
        pick_item: props.pickItemId,
      });

      const target =
        props.type === 'i'
          ? context.root.$t('Button.Item')
          : context.root.$t('Button.Brand');

      if (checked.value) {
        addMsg(context.root.$t('Button.PickAdd', { Target: target }));
      } else {
        addMsg(context.root.$t('Button.PickDelete', { Target: target }));
      }

      await update(props.type === 'i' ? 'items' : 'brands');

      context.emit('update', checked.value);
    };

    return {
      checked,
      handleClick(e) {
        context.emit('click', e);

        if (info === null) {
          showConfirm(context.root.$t('Button.AlertLogin'), () => {
            localStorage.setItem('return.page', context.root.$route.path);
            context.root.$router.push('/auth/login');
          });

          return;
        }

        updatePick();
      },
    };
  },
};
</script>

<style></style>
