<template>
  <header class="layout_header new_page_header">
    <div class="hinner disflex header_2btn">
      <back-button />
      <h2 class="new_title re_detail_name">{{ title }}</h2>
    </div>
  </header>
</template>

<script>
import BackButton from '../../../ui/Buttons/BackButton.vue';

export default {
  components: { BackButton },
  props: ['title'],
};
</script>

<style></style>
